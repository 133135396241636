<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          Forum Message List
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-select v-model="query.grade" placeholder="Grade" class="mr10" :clearable="true"
          @change="handleGradeAndTermChange">
          <el-option v-for="item in options.gradeList" :key="item.value" :label="item.description"
            :value="item.value"></el-option>
        </el-select>
        <el-select v-model="query.term" placeholder="Term" class="mr10" :clearable="true"
          @change="handleGradeAndTermChange">
          <el-option v-for="item in options.termList" :key="item.value" :label="item.description"
            :value="item.value"></el-option>
        </el-select>
        <el-select v-model="query.forumId" placeholder="Lesson" class="mr10" :clearable="true" @change="handleSearch">
          <el-option v-for="item in options.lessonList" :key="item.lessonId" :label="item.name"
            :value="item.lessonId"></el-option>
        </el-select>
        <el-input v-model="query.message" placeholder="Message" class="handle-input mr10" @keyup.enter="handleSearch"
          :clearable="true"></el-input>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Search
        </el-button>
      </div>
      <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
        @sort-change="changeSort" :default-sort="{ prop: 'CreatedTime', order: 'descending' }">
        <el-table-column prop="studentId" label="Student No." sortable="true"
          :sort-orders="['ascending', 'descending', null]" :sort-by="StudentId" :width="this.$widthRatio * 160 + 'px'"
          align="center"></el-table-column>
        <el-table-column prop="studentName" label="Student Name" sortable="true"
          :sort-orders="['ascending', 'descending', null]" :sort-by="StudentName"
          :width="this.$widthRatio * 140 + 'px'"></el-table-column>
        <el-table-column prop="thumbUp" label="Thumb Up" :width="this.$widthRatio * 90 + 'px'" sortable="true"
          :sort-orders="['ascending', 'descending', null]" :sort-by="ThumbUp"></el-table-column>
        <el-table-column prop="thumbDown" label="Thumb Down" :width="this.$widthRatio * 106 + 'px'" sortable="true"
          :sort-orders="['ascending', 'descending', null]" :sort-by="ThumbDown"></el-table-column>
        <el-table-column label="Message">
          <template #default="scope">
            <div>
              <span v-if="scope.row.isTop" class="mr10">
                <el-tag type="danger" effect="dark" size="small" round>
                  top post
                </el-tag>
              </span>
              <span v-if="scope.row.enableBounty" class="mr10">
                <el-tag type="danger" effect="dark" size="small" round>
                  bounty-{{ scope.row.bounty }}points
                </el-tag>
              </span>
              <span v-if="scope.row.isTop" class="mr10">
                <el-tag type="danger" effect="dark" size="small" round>
                  best answer
                </el-tag>
              </span>
            </div>
            <div>{{ scope.row.message }}</div>
            <div v-if="scope.row.quote">{{ scope.row.quote }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="Status" :width="this.$widthRatio * 100 + 'px'"></el-table-column>
        <el-table-column prop="updatedTime" sortable="true" :sort-orders="['ascending', 'descending', null]"
          :sort-by="UpdatedTime" label="Time" :width="this.$widthRatio * 100 + 'px'"
          :formatter="$tableCellFormatter.formatDate"></el-table-column>
        <el-table-column label="Operation" :width="this.$widthRatio * 120 + 'px'" align="center">
          <template #default="scope">
            <el-button type="text" class="red" @click="handleDelete(scope.$index, scope.row)">
              <el-icon>
                <delete />
              </el-icon>
              Delete
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex"
          :page-size="query.pageSize" :total="itemTotal" :page-count="pageTotal"
          @current-change="handlePageChange"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getData, deleteData } from "../../service/api";

export default {
  data() {
    return {
      controllerUrl: "/forum",
      query: {
        grade: null,
        term: null,
        forumId: null,
        message: null,
        orderBy: "CreatedTime",
        orderDirection: "DESC",
        pageIndex: 1,
        pageSize: 20,
      },
      options: {
        gradeList: [],
        termList: [],
        lessonList: [],
      },
      tableData: [],
      dialogTitle: "",
      editVisible: false,
      itemTotal: 0,
      pageTotal: 0,
      form: {},
    };
  },
  created() {
    this.options.gradeList = this.$appSetting.globalConfig.gradeList;
    this.options.termList = this.$appSetting.globalConfig.termList;

    this.loadData();
  },
  methods: {
    loadData() {
      getData(`${this.controllerUrl}/message`, this.query).then((res) => {
        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    handleGradeAndTermChange() {
      if (this.query.grade && this.query.term) {
        let lessonQuery = {
          grade: null,
          term: null,
          orderBy: "Generic",
          orderDirection: "DESC",
          pageIndex: 1,
          pageSize: -1, // -1 for max
        };

        getData("/lesson", lessonQuery).then((res) => {
          let resData = res.result;
          this.options.lessonList = resData.list;
        });
      }
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection = sortData.order === "ascending" ? "ASC" : "DESC";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    handleDelete(index, row) {
      // twice to confirm to delete, and type reason for deleting
      this.$prompt(
        "Are you sure to delete the message? if yes, please type a reason",
        "Prompt",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          inputPattern: /\.*/,
          inputErrorMessage: "Please type a reason to delete the message",
        }
      )
        .then(({ value }) => {
          deleteData(
            `${this.controllerUrl}/message`,
            `${row.messageId}/delete?reason=${value}`
          ).then((res) => {
            if (res.result && res.code === "200") {
              this.$message.success("Delete successfully");
              this.tableData.splice(index, 1);
            } else {
              this.$message.error("Delete failed, error message: " + res.message);
            }
          });
        })
        .catch(() => { });
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}

.table {
  width: 100%;
  font-size: 1.17rem;
}

.red {
  color: #ff0000;
}

.mr10 {
  margin-right: 10px;
}

.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
